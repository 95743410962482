<template>
  <div class="container">
    <start-over />
    <div class="text-center">
      <p class="m-5">
        Start answering the following questions to calculate each startup
        functions domain equity.
      </p>
    </div>
    <div class="question">
      <h4>{{ questions[counter].question }}</h4>
      <div v-if="counter == 0">
        <input
          type="number"
          min="0"
          class="cash form-control"
          v-model="questions[0].answer"
          v-on:keyup.enter="next_question"
          required
        />
      </div>
      <div v-if="counter > 0">
        <div v-for="(answer, idx) in questions[counter].answers" :key="idx">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              :name="answer[0]"
              :id="answer[1]"
              :value="answer[1]"
              v-model="questions[counter].answer"
              v-on:keyup.enter="next_question"
            />
            <label class="form-check-label" :for="answer[1]">
              {{ answer[0] }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <button
      v-if="counter > 0"
      class="btn btn-danger mt-3"
      v-on:click="prev_question"
    >
      back
    </button>
    <button class="btn btn-primary mt-3" v-on:click="next_question">
      Next
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StartOver from "../components/StartOver.vue";
export default {
  components: { StartOver },
  name: "Functions",
  data() {
    return {
      counter: 0,
      questions: [
        {
          _function: "cash",
          question:
            "How much cash and monetary value of assets will the cofounders put in the startup? (EGP)",
          answer: 0
        },
        {
          _function: "idea",
          question: "The idea is",
          answers: {
            1: ["Globally new with no known competitor at all", 1],
            2: ["Mature local competitors exists", 2],
            3: [
              "Local competitors exists but still immature with fund recieved",
              3
            ],
            4: [
              "Local competitors exists but still immature with no fund recieved",
              4
            ],
            5: ["Global competitor exists but with no local competitors", 5]
          },
          answer: 0
        },
        {
          _function: "idea",
          question: "Easiness to copy the same startup idea",
          answers: {
            1: ["Very Easy", 1],
            2: ["Easy", 2],
            3: ["Neutral", 3],
            4: ["Hard", 4],
            5: ["Very Hard", 5]
          },
          answer: 0
        },
        {
          _function: "idea",
          question: "Total Available Market (TAM)",
          answers: {
            1: ["Below 1 million dollars per year", 1],
            2: ["1 to 10 million dollars per year", 2],
            3: ["10 to 100 million dollars per year", 3],
            4: ["100 million to 1 billion dollars per year", 4],
            5: ["Over 1 billion dollars per year", 5]
          },
          answer: 0
        },
        {
          _function: "idea",
          question: "Cost to scale",
          answers: {
            1: ["Very Cheap", 5],
            2: ["Cheap", 4],
            3: ["Neutral", 3],
            4: ["Expensive", 2],
            5: ["Very Expensive", 1]
          },
          answer: 0
        },
        {
          _function: "idea",
          question: "Idea development stage",
          answers: {
            1: ["Only general outlines", 1],
            2: ["The problem is challenged and confirmed", 2],
            3: ["The prototype is tested with real customers", 3],
            4: ["The MVP is ready and tested with no revenue model", 4],
            5: ["The revenue model is confirmed", 5]
          },
          answer: 0
        },
        {
          _function: "management",
          question:
            "On scale from 1 to 6, How hard will be the management? (6 is the hardest)",
          answers: {
            1: ["1", 30],
            2: ["2", 31],
            3: ["3", 32],
            4: ["4", 33],
            5: ["5", 34],
            6: ["6", 35]
          },
          answer: 0
        },
        {
          _function: "technology",
          question: "Technology development will be ...",
          answers: {
            1: ["By cofounder(s)", 30],
            2: ["Inhouse team supervised and managed by cofounder(s)", 25],
            3: ["Outsourced with cofounder(s) follow-up", 32]
          },
          answer: 0
        },
        {
          _function: "operations",
          question:
            "On scale from 1 to 10, How hard will be the operations? (10 is the hardest)",
          answers: {
            1: ["1", 12],
            2: ["2", 14],
            3: ["3", 16],
            4: ["4", 18],
            5: ["5", 20],
            6: ["6", 22],
            7: ["7", 24],
            8: ["8", 26],
            9: ["9", 28],
            10: ["10", 30]
          },
          answer: 0
        },
        {
          _function: "marketing",
          question:
            "On scale from 1 to 10, How hard will be the marketing activites? (10 is the hardest)",
          answers: {
            1: ["1", 12],
            2: ["2", 14],
            3: ["3", 16],
            4: ["4", 18],
            5: ["5", 20],
            6: ["6", 22],
            7: ["7", 24],
            8: ["8", 26],
            9: ["9", 28],
            10: ["10", 30]
          },
          answer: 0
        },
        {
          _function: "research",
          question:
            "On scale from 1 to 10, How hard will be the R&D activities? (10 is the hardest)",
          answers: {
            1: ["1", 6],
            2: ["2", 7],
            3: ["3", 8],
            4: ["4", 9],
            5: ["5", 10],
            6: ["6", 11],
            7: ["7", 12],
            8: ["8", 13],
            9: ["9", 14],
            10: ["10", 15]
          },
          answer: 0
        },
        {
          _function: "finance",
          question:
            "How much equity would you like to assigne for finance and fundraising activities?",
          answers: {
            1: ["1%", 1],
            2: ["2%", 2],
            3: ["3%", 3]
          },
          answer: 0
        },
        {
          _function: "board",
          question:
            "How much equity would you like to reserve for future board?",
          answers: {
            1: ["5%", 5],
            2: ["6%", 6],
            3: ["7%", 7],
            4: ["8%", 8],
            5: ["9%", 9],
            6: ["10%", 10]
          },
          answer: 0
        },
        {
          _function: "advisories",
          question:
            "How much equity would you like to reserve for external advisories?",
          answers: {
            1: ["0%", 0],
            2: ["1%", 1],
            3: ["2%", 2],
            4: ["3%", 3],
            5: ["4%", 4],
            6: ["5%", 5]
          },
          answer: 0
        },
        {
          _function: "employees",
          question:
            "How much equity would you like to reserve for emplyee stock options pool?",
          answers: {
            1: ["5%", 5],
            2: ["6%", 6],
            3: ["7%", 7],
            4: ["8%", 8],
            5: ["9%", 9],
            6: ["10%", 10]
          },
          answer: 0
        }
      ]
    };
  },
  methods: {
    next_question() {
      if (this.counter == this.questions.length - 1) {
        this.$store.dispatch("updateCash", this.questions[0].answer);

        var others = {
          idea: 0,
          management: 0,
          technology: 0,
          operations: 0,
          marketing: 0,
          research: 0,
          finance: 0,
          board: 0,
          advisories: 0,
          employees: 0
        };

        for (var question in this.questions) {
          if (this.questions[question]._function == "idea") {
            others.idea += this.questions[question].answer;
          } else if (this.questions[question]._function == "management") {
            others.management += this.questions[question].answer;
          } else if (this.questions[question]._function == "technology") {
            others.technology += this.questions[question].answer;
          } else if (this.questions[question]._function == "operations") {
            others.operations += this.questions[question].answer;
          } else if (this.questions[question]._function == "marketing") {
            others.marketing += this.questions[question].answer;
          } else if (this.questions[question]._function == "research") {
            others.research += this.questions[question].answer;
          } else if (this.questions[question]._function == "finance") {
            others.finance += this.questions[question].answer;
          } else if (this.questions[question]._function == "board") {
            others.board += this.questions[question].answer;
          } else if (this.questions[question]._function == "advisories") {
            others.advisories += this.questions[question].answer;
          } else if (this.questions[question]._function == "employees") {
            others.employees += this.questions[question].answer;
          }
        }
        this.$store.dispatch("updateOthers", others);
        this.$router.push("/functions/equity");
      } else {
        this.counter += 1;
      }
    },
    prev_question() {
      this.counter -= 1;
    }
  },
  computed: {
    ...mapGetters(["getAll"])
  }
};
</script>

<style scoped>
.form-control {
  width: 150px !important;
}

.btn {
  margin-right: 10px;
}
</style>
