<template>
  <div class="container">
    <div class="text-center">
      <p class="m-5">
        Welcome to this tool for automatic calculation of equity splitting for a
        new startup.<br />
        This equity split mthodology for startups is inspired from Dr. Mohamed
        Hossam Khedr
        <a href="https://ratteb.com/158" target="_blank">article</a>.
      </p>
      <router-link to="/functions" class="btn btn-lg btn-primary"
        >Start</router-link
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {}
};
</script>

<style scoped></style>
