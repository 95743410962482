<template>
  <div class="container p-4">
    <start-over></start-over>
    <div class="text-center mb-5">
      <h3 class="mb-3">
        Equities for different startup component.
      </h3>
      <!-- eslint-disable-next-line prettier/prettier -->
      <h4 style="display: inline;">If total is more than 100% click </h4>
      <button class="btn btn-lg btn-success" v-on:click="normalize">
        Normalize
      </button>
      <p class="mt-3">
        <b>Normalize</b> will cut all equities except cash equity by
        proportional values to reach a total of 100%
      </p>
    </div>
    <div class="col-md-12">
      <div class="row ">
        <div class="col-xl-12 col-lg-12">
          <div class="card l-bg-cherry">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="mb-4">
                <h2 class="card-title mb-0">Cash Equity</h2>
              </div>
              <div class="row align-items-center d-flex">
                <div class="col-8">
                  <h2 class="d-flex align-items-center mb-0">
                    {{ getAll.cashAmount }} EGP
                  </h2>
                </div>
                <div class="col-4 text-right">
                  <span
                    ><h2>{{ getAll.cash }}%</h2></span
                  >
                </div>
              </div>
              <div class="progress mt-1 " data-height="8" style="height: 8px;">
                <div
                  class="progress-bar l-bg-cyan"
                  role="progressbar"
                  data-width="12.5%"
                  aria-valuenow="12.5"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="'width: ' + getAll.cash + '%'"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12">
          <div class="card l-bg-green-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center mb-2 d-flex">
                <div class="col-8">
                  <h2 class="d-flex align-items-center mb-0">
                    Idea Equity
                  </h2>
                </div>
                <div class="col-4 text-right">
                  <span
                    ><h2>{{ getAll.idea }}%</h2></span
                  >
                </div>
              </div>
              <div class="progress mt-1 " data-height="8" style="height: 8px;">
                <div
                  class="progress-bar l-bg-cyan"
                  role="progressbar"
                  data-width="12.5%"
                  aria-valuenow="12.5"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="'width: ' + getAll.idea + '%;'"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h4 class="d-flex align-items-center">
                    Management
                  </h4>
                </div>
                <div class="col-6 text-right">
                  <h4>{{ getAll.management }}%</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h4 class="d-flex align-items-center">
                    Technology
                  </h4>
                </div>
                <div class="col-6 text-right">
                  <h4>{{ getAll.technology }}%</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h4 class="d-flex align-items-center">
                    Operations
                  </h4>
                </div>
                <div class="col-6 text-right">
                  <h4>{{ getAll.operations }}%</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h4 class="d-flex align-items-center">
                    Marketing
                  </h4>
                </div>
                <div class="col-6 text-right">
                  <h4>{{ getAll.marketing }}%</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h4 class="d-flex align-items-center">
                    R&D
                  </h4>
                </div>
                <div class="col-6 text-right">
                  <h4>{{ getAll.research }}%</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h4 class="d-flex align-items-center">
                    Finance
                  </h4>
                </div>
                <div class="col-6 text-right">
                  <h4>{{ getAll.finance }}%</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card l-bg-orange-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h4 class="d-flex align-items-center">
                    Board
                  </h4>
                </div>
                <div class="col-6 text-right">
                  <h4>{{ getAll.board }}%</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card l-bg-orange-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h4 class="d-flex align-items-center">
                    Advisories
                  </h4>
                </div>
                <div class="col-6 text-right">
                  <h4>{{ getAll.advisories }}%</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card l-bg-orange-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h4 class="d-flex align-items-center">
                    Employees
                  </h4>
                </div>
                <div class="col-6 text-right">
                  <h4>{{ getAll.employees }}%</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12">
          <div class="card l-bg-cherry">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-8">
                  <h2 class="d-flex align-items-center mb-0">
                    Total
                  </h2>
                </div>
                <div class="col-4 text-right">
                  <span
                    ><h2>{{ totalEquity }}%</h2></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-3">
      <div class="col">
        <div class="text-right">
          <router-link class="btn btn-lg btn-primary" to="/cofounders"
            >Next</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FunctionsEquity",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getAll"]),
    totalEquity() {
      var total = 0;
      for (var value in this.getAll) {
        total = parseFloat(total) + parseFloat(this.getAll[value]);
      }
      return (total - this.getAll.cashAmount).toFixed(0);
    }
  },
  methods: {
    normalize() {
      this.$store.dispatch("normalizeAction");
    }
  }
};
</script>

<style></style>
