<template>
  <div class="container">
    <start-over></start-over>
    <div class="form-group mt-2">
      <input
        type="text"
        class="form-control"
        id="cofounder"
        placeholder="Name"
        ref="name"
        v-model="newCofounder.name"
        v-on:keyup.enter="addCofounder"
      />
      <input
        type="text"
        class="form-control"
        id="role"
        placeholder="Main Role"
        v-model="newCofounder.role"
        v-on:keyup.enter="addCofounder"
      />
    </div>
    <div class="form-group mt-2">
      <div class="row">
        <div class="col-xl-7">
          <label for="cash"
            >How much <b>cash</b> will this cofounder contribute to the startup?
            (In EGP)</label
          >
          <div>{{ newCofounder.cashAmount }} EGP</div>
          <input
            type="range"
            class="form-control"
            id="cash"
            placeholder="EGP"
            min="0"
            :max="remaining.cashAmount"
            step="10000"
            v-model="newCofounder.cashAmount"
          />
        </div>
        <div class="col-xl-5">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h5 class="d-flex align-items-center">
                    Remaining Cash
                  </h5>
                </div>
                <div class="col-6 text-right">
                  <h5>
                    {{ remaining.cashAmount - newCofounder.cashAmount }} EGP
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group mt-2">
      <div class="row">
        <div class="col-xl-7">
          <label for="idea"
            >How much has this cofounder contributed to the
            <b> idea development</b> so far? (Percentage)</label
          >
          <div>{{ newCofounder.idea }}%</div>
          <input
            type="range"
            :max="remainingPercentage.idea"
            step="5"
            class="form-control"
            id="idea"
            placeholder="Idea development %"
            min="0"
            v-model="newCofounder.idea"
          />
        </div>
        <div class="col-xl-5">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h5 class="d-flex align-items-center">
                    Remaining idea equity
                  </h5>
                </div>
                <div class="col-6 text-right">
                  <h5>{{ remaining.idea.toFixed(2) }}%</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group mt-2">
      <div class="row">
        <div class="col-xl-7">
          <label for="management"
            >How much will this cofounder contribute to the <b> management</b>?
            (Percentage)</label
          >
          <div>{{ newCofounder.management }}%</div>
          <input
            type="range"
            :max="remainingPercentage.management"
            step="5"
            class="form-control"
            id="management"
            placeholder="Management %"
            min="0"
            v-model="newCofounder.management"
          />
        </div>
        <div class="col-xl-5">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h5 class="d-flex align-items-center">
                    Remaining management equity
                  </h5>
                </div>
                <div class="col-6 text-right">
                  <h5>{{ remaining.management.toFixed(2) }}%</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group mt-2">
      <div class="row">
        <div class="col-xl-7">
          <label for="technology"
            >How much will this cofounder contribute to the
            <b> technology development</b> activities? (Percentage)</label
          >
          <div>{{ newCofounder.technology }}%</div>
          <input
            type="range"
            :max="remainingPercentage.technology"
            step="5"
            class="form-control"
            id="technology"
            placeholder="Technology %"
            min="0"
            v-model="newCofounder.technology"
          />
        </div>
        <div class="col-xl-5">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h5 class="d-flex align-items-center">
                    Remaining tech equity
                  </h5>
                </div>
                <div class="col-6 text-right">
                  <h5>{{ remaining.technology.toFixed(2) }}%</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group mt-2">
      <div class="row">
        <div class="col-xl-7">
          <label for="operations"
            >How much will this cofounder contribute to the
            <b> operations</b> activities? (Percentage)</label
          >
          <div>{{ newCofounder.operations }}%</div>
          <input
            type="range"
            :max="remainingPercentage.operations"
            step="5"
            class="form-control"
            id="operations"
            placeholder="Operations %"
            min="0"
            v-model="newCofounder.operations"
          />
        </div>
        <div class="col-xl-5">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h5 class="d-flex align-items-center">
                    Remaining operations equity
                  </h5>
                </div>
                <div class="col-6 text-right">
                  <h5>{{ remaining.operations.toFixed(2) }}%</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group mt-2">
      <div class="row">
        <div class="col-xl-7">
          <label for="marketing"
            >How much will this cofounder contribute to the
            <b> marketing</b> activities? (Percentage)</label
          >
          <div>{{ newCofounder.marketing }}%</div>
          <input
            type="range"
            :max="remainingPercentage.marketing"
            step="5"
            class="form-control"
            id="marketing"
            placeholder="Marketing %"
            min="0"
            v-model="newCofounder.marketing"
          />
        </div>
        <div class="col-xl-5">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h5 class="d-flex align-items-center">
                    Remaining marketing equity
                  </h5>
                </div>
                <div class="col-6 text-right">
                  <h5>{{ remaining.marketing.toFixed(2) }}%</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group mt-2">
      <div class="row">
        <div class="col-xl-7">
          <label for="research"
            >How much will this cofounder contribute to the
            <b> research</b> activities? (Percentage)</label
          >
          <div>{{ newCofounder.research }}%</div>
          <input
            type="range"
            :max="remainingPercentage.research"
            step="5"
            class="form-control"
            id="research"
            placeholder="R&D %"
            min="0"
            v-model="newCofounder.research"
          />
        </div>
        <div class="col-xl-5">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h5 class="d-flex align-items-center">
                    Remaining R&D equity
                  </h5>
                </div>
                <div class="col-6 text-right">
                  <h5>{{ remaining.research.toFixed(2) }}%</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group mt-2">
      <div class="row">
        <div class="col-xl-7">
          <label for="finance"
            >How much will this cofounder contribute to the
            <b> finance and fundraising</b> activities? (Percentage)</label
          >
          <div>{{ newCofounder.finance }}%</div>
          <input
            type="range"
            :max="remainingPercentage.finance"
            step="5"
            class="form-control"
            id="finance"
            placeholder="Operations %"
            v-model="newCofounder.finance"
          />
        </div>
        <div class="col-xl-5">
          <div class="card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"></div>
              <div class="row align-items-center d-flex">
                <div class="col-6">
                  <h5 class="d-flex align-items-center">
                    Remaining finance equity
                  </h5>
                </div>
                <div class="col-6 text-right">
                  <h5>{{ remaining.finance.toFixed(2) }}%</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col">
        <button class="btn btn-lg btn-success mt-3" v-on:click="addCofounder">
          Add
        </button>
      </div>
      <div class="col text-right">
        <button class="btn btn-lg btn-primary mt-3" v-on:click="next_page">
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Cofounders",
  data() {
    return {
      newCofounder: {
        name: "",
        role: "",
        cashAmount: 0,
        idea: 0,
        management: 0,
        technology: 0,
        operations: 0,
        marketing: 0,
        research: 0,
        finance: 0
      },
      cofounders: []
    };
  },
  methods: {
    addCofounder() {
      if (this.newCofounder.name) {
        this.cofounders.push({
          name: this.newCofounder.name,
          role: this.newCofounder.role,
          cashAmount: parseFloat(this.newCofounder.cashAmount),
          idea: this.newCofounder.idea / 100,
          management: this.newCofounder.management / 100,
          technology: this.newCofounder.technology / 100,
          operations: this.newCofounder.operations / 100,
          marketing: this.newCofounder.marketing / 100,
          research: this.newCofounder.research / 100,
          finance: this.newCofounder.finance / 100,
          equities: {
            cash:
              (parseFloat(this.newCofounder.cashAmount) /
                parseFloat(this.getAll.cashAmount)) *
              parseFloat(this.getAll.cash),
            idea:
              (parseFloat(this.newCofounder.idea) / 100) *
              parseFloat(this.getAll.idea),
            management:
              (parseFloat(this.newCofounder.management) / 100) *
              parseFloat(this.getAll.management),
            technology:
              (parseFloat(this.newCofounder.technology) / 100) *
              parseFloat(this.getAll.technology),
            operations:
              (parseFloat(this.newCofounder.operations) / 100) *
              parseFloat(this.getAll.operations),
            marketing:
              (parseFloat(this.newCofounder.marketing) / 100) *
              parseFloat(this.getAll.marketing),
            research:
              (parseFloat(this.newCofounder.research) / 100) *
              parseFloat(this.getAll.research),
            finance:
              (parseFloat(this.newCofounder.finance) / 100) *
              parseFloat(this.getAll.finance)
          }
        });
        for (var item in this.newCofounder) {
          this.newCofounder[item] = 0;
        }
        this.newCofounder.name = "";
        this.newCofounder.role = "";
        this.$refs.name.focus();
      }
    },
    next_page() {
      if (this.cofounders.length > 0) {
        if (this.newCofounder.name.length > 0) {
          this.addCofounder();
        }
        this.$store.dispatch("resetCofoundersData");
        this.$store.dispatch("addCofounders", this.cofounders);
        this.$router.push("/cofounders/equity");
      }
    }
  },
  computed: {
    ...mapGetters(["getAll"]),
    remaining() {
      let cashCofounders = 0;
      let ideaCofounders = 0;
      let managementCofounders = 0;
      let technologyCofounders = 0;
      let operationsCofounders = 0;
      let marketingCofounders = 0;
      let researchCofounders = 0;
      let financeCofounders = 0;
      for (let cofounder in this.cofounders) {
        cashCofounders += this.cofounders[cofounder].cashAmount;
        ideaCofounders += this.cofounders[cofounder].equities.idea;
        managementCofounders += this.cofounders[cofounder].equities.management;
        technologyCofounders += this.cofounders[cofounder].equities.technology;
        operationsCofounders += this.cofounders[cofounder].equities.operations;
        marketingCofounders += this.cofounders[cofounder].equities.marketing;
        researchCofounders += this.cofounders[cofounder].equities.research;
        financeCofounders += this.cofounders[cofounder].equities.finance;
      }
      return {
        cashAmount: this.getAll.cashAmount - cashCofounders,
        idea:
          this.getAll.idea -
          ideaCofounders -
          (this.newCofounder.idea / 100) * this.getAll.idea,
        management:
          this.getAll.management -
          managementCofounders -
          (this.newCofounder.management / 100) * this.getAll.management,
        technology:
          this.getAll.technology -
          technologyCofounders -
          (this.newCofounder.technology / 100) * this.getAll.technology,
        operations:
          this.getAll.operations -
          operationsCofounders -
          (this.newCofounder.operations / 100) * this.getAll.operations,
        marketing:
          this.getAll.marketing -
          marketingCofounders -
          (this.newCofounder.marketing / 100) * this.getAll.marketing,
        research:
          this.getAll.research -
          researchCofounders -
          (this.newCofounder.research / 100) * this.getAll.research,
        finance:
          this.getAll.finance -
          financeCofounders -
          (this.newCofounder.finance / 100) * this.getAll.finance
      };
    },
    remainingPercentage() {
      let ideaCofounders = 0;
      let managementCofounders = 0;
      let technologyCofounders = 0;
      let operationsCofounders = 0;
      let marketingCofounders = 0;
      let researchCofounders = 0;
      let financeCofounders = 0;
      for (let cofounder in this.cofounders) {
        ideaCofounders += this.cofounders[cofounder].idea;
        managementCofounders += this.cofounders[cofounder].management;
        technologyCofounders += this.cofounders[cofounder].technology;
        operationsCofounders += this.cofounders[cofounder].operations;
        marketingCofounders += this.cofounders[cofounder].marketing;
        researchCofounders += this.cofounders[cofounder].research;
        financeCofounders += this.cofounders[cofounder].finance;
      }
      return {
        idea: 100 - ideaCofounders * 100,
        management: 100 - managementCofounders * 100,
        technology: 100 - technologyCofounders * 100,
        operations: 100 - operationsCofounders * 100,
        marketing: 100 - marketingCofounders * 100,
        research: 100 - researchCofounders * 100,
        finance: 100 - financeCofounders * 100
      };
    }
  }
};
</script>

<style scoped>
.form-control {
  margin-bottom: 15px;
}
.card {
  margin-bottom: 0px;
}
.card-statistic-3 > div > div > h5 {
  margin-bottom: 0px;
}
</style>
