import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Functions from "../views/Functions.vue";
import FunctionsEquity from "../views/FunctionsEquity.vue";
import Cofounders from "../views/Cofounders.vue";
import CofoundersEquity from "../views/CofoundersEquity.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/functions",
    name: "Functions",
    component: Functions
  },
  {
    path: "/functions/equity",
    name: "FunctionsEquity",
    component: FunctionsEquity
  },
  {
    path: "/cofounders",
    name: "Cofounders",
    component: Cofounders
  },
  {
    path: "/cofounders/equity",
    name: "CofoundersEquity",
    component: CofoundersEquity
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
