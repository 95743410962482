<template>
  <div id="app">
    <div class="container">
      <div class="text-center my-4">
        <h1>Startup Equity Split Calculator</h1>
        <h4>v1.0 beta</h4>
        <h4>by <a href="https://www.ihabtag.com">Ihab Tag</a></h4>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  title: "Startup Equity Splitter v1.0 beta"
};
</script>

<style>
@import "./assets/style.scss";
</style>
