import Vue from "vue";
import Vuex from "vuex";

// Importing modules
import functions from "./modules/functions";
import cofounders from "./modules/cofounders";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    functions,
    cofounders
  }
});
