<template>
  <div class="container">
    <start-over></start-over>
    <div class="row">
      <div class="col-xl-4 col-lg-6">
        <div class="card l-bg-orange-dark">
          <div class="card-statistic-3 p-4">
            <div class="card-icon card-icon-large"></div>
            <div class="row align-items-center d-flex">
              <div class="col-6">
                <h4 class="d-flex align-items-center">
                  Board
                </h4>
              </div>
              <div class="col-6 text-right">
                <h4>{{ getAll.board }}%</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6">
        <div class="card l-bg-orange-dark">
          <div class="card-statistic-3 p-4">
            <div class="card-icon card-icon-large"></div>
            <div class="row align-items-center d-flex">
              <div class="col-6">
                <h4 class="d-flex align-items-center">
                  Advisories
                </h4>
              </div>
              <div class="col-6 text-right">
                <h4>{{ getAll.advisories }}%</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6">
        <div class="card l-bg-orange-dark">
          <div class="card-statistic-3 p-4">
            <div class="card-icon card-icon-large"></div>
            <div class="row align-items-center d-flex">
              <div class="col-6">
                <h4 class="d-flex align-items-center">
                  Employees
                </h4>
              </div>
              <div class="col-6 text-right">
                <h4>{{ getAll.employees }}%</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-xl-6"
        v-for="(cofounder, idx) in getCofounders"
        :key="idx"
      >
        <div class="card bg-light mb-3">
          <div class="card-header text-center pt-4">
            <div class="equity p-5">
              {{
                (
                  cofounder.equities.cash +
                  cofounder.equities.idea +
                  cofounder.equities.management +
                  cofounder.equities.technology +
                  cofounder.equities.operations +
                  cofounder.equities.marketing +
                  cofounder.equities.research +
                  cofounder.equities.finance
                ).toFixed(2)
              }}%
            </div>
            <h1 class="mt-4">{{ cofounder.name }}</h1>
            <h3>{{ cofounder.role }}</h3>
          </div>
          <div class="card-body">
            <div class="card l-bg-cherry">
              <div class="card-statistic-3 p-3">
                <div class="card-icon card-icon-large"></div>
                <div class="mb-2">
                  <h4 class="card-title mb-0">Cash Equity</h4>
                </div>
                <div class="row align-items-center d-flex">
                  <div class="col-8">
                    <h4 class="d-flex align-items-center mb-0">
                      {{ cofounder.cashAmount }} EGP
                    </h4>
                  </div>
                  <div class="col-4 text-right">
                    <span
                      ><h4>{{ cofounder.equities.cash.toFixed(2) }}%</h4></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card l-bg-blue-dark">
              <div class="card-statistic-3 p-3">
                <div class="row align-items-center d-flex">
                  <div class="col-8">
                    <h4 class="d-flex align-items-center mb-0">
                      Idea
                    </h4>
                  </div>
                  <div class="col-4 text-right">
                    <span
                      ><h4>{{ cofounder.equities.idea.toFixed(2) }}%</h4></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card l-bg-blue-dark">
              <div class="card-statistic-3 p-3">
                <div class="row align-items-center d-flex">
                  <div class="col-8">
                    <h4 class="d-flex align-items-center mb-0">
                      Management
                    </h4>
                  </div>
                  <div class="col-4 text-right">
                    <span
                      ><h4>
                        {{ cofounder.equities.management.toFixed(2) }}%
                      </h4></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card l-bg-blue-dark">
              <div class="card-statistic-3 p-3">
                <div class="row align-items-center d-flex">
                  <div class="col-8">
                    <h4 class="d-flex align-items-center mb-0">
                      Technology
                    </h4>
                  </div>
                  <div class="col-4 text-right">
                    <span
                      ><h4>
                        {{ cofounder.equities.technology.toFixed(2) }}%
                      </h4></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card l-bg-blue-dark">
              <div class="card-statistic-3 p-3">
                <div class="row align-items-center d-flex">
                  <div class="col-8">
                    <h4 class="d-flex align-items-center mb-0">
                      Operations
                    </h4>
                  </div>
                  <div class="col-4 text-right">
                    <span
                      ><h4>
                        {{ cofounder.equities.operations.toFixed(2) }}%
                      </h4></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card l-bg-blue-dark">
              <div class="card-statistic-3 p-3">
                <div class="row align-items-center d-flex">
                  <div class="col-8">
                    <h4 class="d-flex align-items-center mb-0">
                      Marketing
                    </h4>
                  </div>
                  <div class="col-4 text-right">
                    <span
                      ><h4>
                        {{ cofounder.equities.marketing.toFixed(2) }}%
                      </h4></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card l-bg-blue-dark">
              <div class="card-statistic-3 p-3">
                <div class="row align-items-center d-flex">
                  <div class="col-8">
                    <h4 class="d-flex align-items-center mb-0">
                      R&D
                    </h4>
                  </div>
                  <div class="col-4 text-right">
                    <span
                      ><h4>
                        {{ cofounder.equities.research.toFixed(2) }}%
                      </h4></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card l-bg-blue-dark">
              <div class="card-statistic-3 p-3">
                <div class="row align-items-center d-flex">
                  <div class="col-8">
                    <h4 class="d-flex align-items-center mb-0">
                      Finance and Fundraising
                    </h4>
                  </div>
                  <div class="col-4 text-right">
                    <span
                      ><h4>
                        {{ cofounder.equities.finance.toFixed(2) }}%
                      </h4></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CofoundersEquity",
  computed: {
    ...mapGetters(["getAll", "getCofounders"])
  }
};
</script>

<style scoped>
.equity {
  font-size: 2.5rem;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  margin: auto;
  box-shadow: 0 0 0 3px #a2a2a2;
  line-height: 10rem;
}
</style>
