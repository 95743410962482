const state = {
  cofoundersData: []
};

const getters = {
  getCofounders: state => state.cofoundersData
};

const actions = {
  addCofounders(context, array) {
    array.forEach(obj => {
      context.commit("addCofounders", obj);
    });
  },
  resetCofoundersData(context) {
    context.commit("resetCofoundersData");
  }
};

const mutations = {
  addCofounders(state, obj) {
    state.cofoundersData.push(obj);
  },
  resetCofoundersData(state) {
    state.cofoundersData = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
