const state = {
  cashAmount: 0.0,
  cash: 0.0,
  idea: 0.0,
  management: 0.0,
  technology: 0.0,
  operations: 0.0,
  marketing: 0.0,
  research: 0.0,
  finance: 0.0,
  board: 0.0,
  advisories: 0.0,
  employees: 0.0
};

const getters = {
  getAll: state => state
};

const actions = {
  updateCash(context, value) {
    var equity = 0;
    if (value <= 100000) {
      equity = 4;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 200000) {
      equity = 5;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 300000) {
      equity = 6;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 400000) {
      equity = 7;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 500000) {
      equity = 8;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 600000) {
      equity = 9;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 700000) {
      equity = 10;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 800000) {
      equity = 11;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 900000) {
      equity = 12;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 1000000) {
      equity = 13;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 1100000) {
      equity = 14;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 1250000) {
      equity = 15;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 1400000) {
      equity = 16;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 1550000) {
      equity = 17;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 1700000) {
      equity = 18;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 1850000) {
      equity = 19;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 2000000) {
      equity = 20;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 2200000) {
      equity = 21;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 2400000) {
      equity = 22;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 2600000) {
      equity = 23;
      context.commit("updateCash", [value, equity]);
    } else if (value <= 2800000) {
      equity = 24;
      context.commit("updateCash", [value, equity]);
    } else if (value >= 3000000) {
      equity = 25;
      context.commit("updateCash", [value, equity]);
    }
  },

  updateOthers(context, object) {
    context.commit("updateOthers", object);
  },
  normalizeAction(context) {
    context.commit("normalizeMutation");
  }
};

const mutations = {
  updateCash(state, payload) {
    state.cashAmount = payload[0];
    state.cash = payload[1];
  },

  updateOthers(state, object) {
    state.idea = object.idea;
    state.management = object.management;
    state.technology = object.technology;
    state.operations = object.operations;
    state.marketing = object.marketing;
    state.research = object.research;
    state.finance = object.finance;
    state.board = object.board;
    state.advisories = object.advisories;
    state.employees = object.employees;
  },

  normalizeMutation(state) {
    var sum = 0;
    for (var v in state) {
      sum += parseFloat(state[v]);
    }
    // sum -= state.cashAmount;
    var denominator = sum - state.cash - parseFloat(state.cashAmount);
    var numerator = 100 - state.cash;
    var percentage = numerator / denominator;

    if (sum > 0) {
      state.idea = (state.idea * percentage).toFixed(2);
      state.management = (state.management * percentage).toFixed(2);
      state.technology = (state.technology * percentage).toFixed(2);
      state.operations = (state.operations * percentage).toFixed(2);
      state.marketing = (state.marketing * percentage).toFixed(2);
      state.research = (state.research * percentage).toFixed(2);
      state.finance = (state.finance * percentage).toFixed(2);
      state.board = (state.board * percentage).toFixed(2);
      state.advisories = (state.advisories * percentage).toFixed(2);
      state.employees = (state.employees * percentage).toFixed(2);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
