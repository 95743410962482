import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAnalytics from "vue-analytics";
import store from "./store";

import "normalize.css";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import titleMixin from "./mixins/titleMixin";
Vue.mixin(titleMixin);

Vue.use(VueAnalytics, {
  id: "G-TQS7E4Z6TR"
});

import StartOver from "./components/StartOver";

Vue.component("StartOver", StartOver);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
