<template>
  <div class="text-center mb-5">
    <button class="btn btn-lg btn-danger" v-on:click="confirm">
      Start from the beginning
    </button>
  </div>
</template>

<script>
export default {
  name: "StartOver",
  methods: {
    confirm() {
      var res = confirm("You will lose all data .. Are you sure?");
      if (res) {
        this.$store.dispatch("resetCofoundersData");
        this.$router.push("/");
      }
    }
  }
};
</script>

<style></style>
